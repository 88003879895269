import 'nprogress/nprogress.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-loading-skeleton/dist/skeleton.css'
import '../styles/index.scss'

import type { AppProps } from 'next/app'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import * as yup from 'yup'
import { ResizeObserver } from '@juggle/resize-observer'
import { RootStoreProvider } from '../components/common/root-store-provider/root-store-provider'
import PageProgress from '../components/common/page-progress/page-progress'
import '../components/common/page-progress/page-progress.scss'
import {ErrorBoundary} from '../components/common/error-boundary/error-boundary'
import {
  DehydratedState,
  HydrationBoundary, QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { AnalyticsInject, AnalyticsDebuggerProvider } from '@features/Analytics'
import { useMemo, useState } from 'react'
import Head from "next/head";
import * as Sentry from '@sentry/react';
// Enable Day.js extensions
dayjs.extend(duration)

// Customize yup error formatting
const formatError = yup.ValidationError.formatError
yup.ValidationError.formatError = function (message, parameters) {
  if (!parameters.label) {
    parameters.label = (parameters.path + '')
      .replace(
        /([a-z])([A-Z])/g,
        (_, match1, match2) => `${match1} ${match2.toLowerCase()}`
      )
      .replace(/^[a-z]/, (match) => match.toUpperCase())
  }
  return formatError.call(this, message, parameters)
}

// Polyfill ResizeObserver
if (typeof window !== 'undefined' && !window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver
}

function MyApp({
  Component,
  pageProps,
}: AppProps & {
  pageProps: {
    dehydratedState: DehydratedState
  }
}) {
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30_000),
      },
    },
    queryCache:  new QueryCache({
      onError: (error) => {
        console.log(error);
        Sentry.captureException(error);
      },
    }),
  }));

  const queryClientMemoized = useMemo(
    () => ({ value: queryClient }),
    [queryClient]
  )

  return (
    <>
      <Head>
        <title>Eclipse</title>
      </Head>
      <ErrorBoundary>
        <QueryClientProvider client={queryClientMemoized.value}>
          <HydrationBoundary state={pageProps.dehydratedState}>
            <AnalyticsDebuggerProvider>
              <RootStoreProvider>
                <PageProgress />
                <Component {...pageProps} />
                <AnalyticsInject />
              </RootStoreProvider>
            </AnalyticsDebuggerProvider>
          </HydrationBoundary>
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  )
}

export default MyApp
