export enum SIGNS {
  'Aries' = 'Aries',
  'Taurus' = 'Taurus',
  'Gemini' = 'Gemini',
  'Cancer' = 'Cancer',
  'Leo' = 'Leo',
  'Virgo' = 'Virgo',
  'Libra' = 'Libra',
  'Scorpio' = 'Scorpio',
  'Sagittarius' = 'Sagittarius',
  'Capricorn' = 'Capricorn',
  'Aquarius' = 'Aquarius',
  'Pisces' = 'Pisces',
}

export const getCompatibility = (userSign: string, partnerSign: string) => {
  switch (userSign) {
    case 'Aries':
      switch (partnerSign) {
        case 'Aries':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              "You totally understand your partner's needs — it is like looking into a mirror. An Aries-Aries relationship is a strong match. They can be great friends too, their compatibility is extremely high. If two Aries will learn how to work together, they can achieve a lot!",
          };
        case 'Taurus':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              "Aries-Taurus relationships are really frequent. Taurus’s love and Aries’s passion create a perfect balance. It's not that easy for those Signs to create a strong, friendly relationship. Keep in mind that Aries and Taurus are two people of widely different personalities, and both partners must put effort into this relationship.",
          };
        case 'Gemini':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Aries and Gemini tend to share the same goals. They are a perfect match, especially when Aries allows Gemini safe personal space. They are different, yet, they can be great friends, especially if they share a sense of humor and don't forget to have fun ",
          };
        case 'Cancer':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Aries and Cancer are really different, but that’s the case of opposites attracting. Their affair may bring harmony and last a lifetime if both signs want to work on their relationship. Their friendship is a union of opposites, but surprisingly they are a great match. ',
          };
        case 'Leo':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              "Aries-Leo relationships are about action and passion. Both partners need to respect each other's need for praise and recognition. These two fire signs are highly compatible, their relationship is full of energy, confidence, and passion\n",
          };
        case 'Virgo':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Aries-Virgo relationships may be challenging. You might think that you and your partner are the total opposites. Booth signs need to look for a common goal to unite their bond. Aries and Virgo are not zodiac signs you would expect to see together as friends, but they can teach each other a lot if they will seek similarities, not differences.',
          };
        case 'Libra':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Aries and Libra are directly opposite — but they can create a good match. Both signs need to compromise and leave their ego behind. Aries-Libra's friendship compatibility looks a little complicated. But the harmony coming from the union of Mars and Venus may bring deep and gentle understanding into this relationship. ",
          };
        case 'Scorpio':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              "Aries-Scorpio relationships are dynamic and passionate. Partners must respect each other's need for privacy. They can be a great duo if both partners will learn how to cooperate. Aries and Scorpio can be either strong allies or mortal enemies. That’s why patience and respect are crucial for these relationships. ",
          };
        case 'Sagittarius':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Sagittarius and Aries have a lot in common. It’s a match made in heaven — these two will never get bored by each other. They make good friends too — their passion for life makes them a great team. Aries and Sagittarius are both enthusiastic and cheerful, they comprehend each other well. ',
          };
        case 'Capricorn':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Aries-Capricorn relationships are controversial. They have a lot in common, they share this stubborn energy and understand each other perfectly, but they need to work hard to maintain balance. Aries and Capricorn can become lifetime friends if they have a deep level of respect for one another.',
          };
        case 'Aquarius':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Aquarius and Aries are a highly creative pair. They are like playful best friends. This friendship can be reborn into a gentle love bond. But if not, they will become great buddies. The best aspect of their friendship is finding commonality in the differences. When so, their bond is full of fun and excitement',
          };
        case 'Pisces':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Aries-Pisces relationships are never boring. They are not naturally super compatible as friends and are difficult to combine. Both Signs must work hard to make a relationship last. But Aries’s directness with Pisces’s intuitive perception makes them a great team. They have a lot in common - generosity, creativity and guilty pleasure for luxuries \n',
          };
      }
      break;
    case 'Taurus':
      switch (partnerSign) {
        case 'Aries':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              "Aries-Taurus relationships are really frequent. Taurus’s love and Aries’s passion create a perfect balance. It's not that easy for those Signs to create a strong, friendly relationship. Keep in mind that Aries and Taurus are two people of widely different personalities, and both partners must put effort into this relationship.",
          };
        case 'Taurus':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Taurus-Taurus relationships may turn into the love connection of a lifetime. Partners share a need for long-term security and stability. If two Taurus become friends, they will have a very-very steady relationship. Their compatibility is extremely high, and they will value safety. ',
          };
        case 'Gemini':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Gemini-Taurus relationships are dynamic yet structured. Their relationships need time for development, so both Signs must be patient with each other to create a bond. If both partners take their time, the friendships will be surprisingly strong. In fact, they work better as friends than as lovers.',
          };
        case 'Cancer':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Cancer and Taurus are both traditional and family-oriented. It's easy for them to create a connection as long as they share the need for security and stability. Taurus and Cancer are very compatible friends, their relationship will definitely stand the test of time. Their friendship is all about support, as both Signs adore taking care of each other. ",
          };
        case 'Leo':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Leo and Taurus are a great couple. They are both loyal and have a thing for little life pleasures. Their love for the finer things in life may unite them. Loyalty will also make their friendship a life-long match full of mutual respect. If both Signs control their jealousy and will admit the important role of partner in their life, they will surely make a great match. ',
          };
        case 'Virgo':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Virgo and Taurus are both Earth signs. Their love language is affection and gifts. Together, they create a great couple that needs to learn to agree to disagree. They can be good friends too — both Signs are reliable and dependable. Their friendship will get better and better over time, but Virgo and Taurus must avoid boredom, that might ruin their connection. ',
          };
        case 'Libra':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Libra and Taurus are karmically linked. Their relationships are warming and welcoming, and they have much to teach one another as lovers and as friends. Their friendship is like the joining of two parts to completion. Libra and Taurus “get” each other, and share a love for art, culture, and quiet time. Still, they are different in many ways, and both Signs must learn to adapt and compromise.',
          };
        case 'Scorpio':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Scorpio and Taurus have a special, bit of complex connection. Both partners need to learn to agree to disagree. As friends, they feel a strong connection, and this can be a strong bond for life. They complement each other so well that you may think of two puzzle pieces. But still, Scorpio and Taurus must work hard to make their relationship last',
          };
        case 'Sagittarius':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Sagittarius and Taurus are a complementary match. Their relationships are beneficial — they have much to gain from their bond. Both Signs are extremely lucky, and their friendship is all about respect and communication. As friends, they will inspire each other - Sagittarius will benefit from the Taurus’s stubborn energy and Taurus will learn a lot about feelings.',
          };
        case 'Capricorn':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Capricorn and Taurus admire one another’s dedication and strength and form a great couple. They share a need for traditional, rational, sensible, and family-oriented relationships. As friends, Taurus and Capricorn must seek different approaches. They know well how to make life a stable and peaceful place, that's why it's important to remember to have fun. Their friendship is about long and deep conversations and mental connection. ",
          };
        case 'Aquarius':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              'Aquarius and Taurus are extremely opposite. They can create a great alliance if they will learn how to balance each other out. Their friendship compatibility is not that high, as opposites do not always attract. But they have great potential, especially when Taurus’ logic complements Aquarius’ romantic view.',
          };
        case 'Pisces':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Pisces and Taurus usually have harmonious, dreamy, and stable relationships. These two Signs have much to offer one another. They can become close, nurturing friends. They are definitely soulmates with mutual interests and respect! As friends, they will understand and support each other, especially if they share the same values.',
          };
      }
      break;
    case 'Gemini':
      switch (partnerSign) {
        case 'Aries':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Aries and Gemini tend to share the same goals. They are a perfect match, especially when Aries allows Gemini safe personal space. They are different, yet, they can be great friends, especially if they share the sense of humor and don't forget to have fun ",
          };
        case 'Taurus':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Gemini-Taurus relationships are dynamic yet structured. Their relationships need time for development, so both Signs must be patient with each other to create a bond. If both partners take their time, the friendships will be surprisingly strong. In fact, they work better as friends than as lovers.',
          };
        case 'Gemini':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Gemini-Gemini relationships are unpredictable but always warm. You two understand each other perfectly, it will let you create a strong connection. When two Gemini become friends, it’s like a friendship of four, not two people. Even though they will have a hard time trusting one another, their compatibility is pretty high. When the trust is here, there are no obstacles left, and both partners will benefit from this funny, yet deep and nurturing companionship.\n',
          };
        case 'Cancer':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Gemini and Cancer are polar opposites, yet they are a great complement to each other, as Cancer is about emotions and Gemini is a thinker. Together they make a good team. As friends, they can create fascinating, extraordinary partnerships. Gemini will take care of Cancer and Cancer will fully accept and support Gemini. They are both extremely social and share many similarities. With proper communication, they will not have any conflicts.',
          };
        case 'Leo':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Leo-Gemini relationships might be a great chance to realize your ambitions because both Signs have lots of energy. Combined, they can move mountains. Friendship comes naturally between these two. They know how to have fun and still spend quality time. Leo and Gemini will support one another’s dreams, standing by their companions' side. Together they will shine in the rays of support, positive thinking, and curiosity.",
          };
        case 'Virgo':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              "Virgo-Gemini relationships are ruled by Mercury. Both Signs need to take their time and remember that both partners' attitudes are worth understanding. As friends, they may irritate each other, they would rather be great colleagues than companions. Still, this relationship has potential, if both Signs will learn to display sensitivity and maturity.",
          };
        case 'Libra':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Libra and Gemini are a great match. Together they combine the forces of love and communication. Their friendship is about deep mental connection. Passion for discovery and exploration unites them. Their favorite thing to do together is experience new places, sensations, and people.',
          };
        case 'Scorpio':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Scorpio and Gemini must acknowledge each other’s differences and learn how to accept them. Scorpio-Gemini relationships are all about passion, loyalty, and mental connection. Scorpio-Gemini friendship may be strong and full of mutual respect, especially if both companions are fascinated by each other’s differences. To avoid misunderstandings both Signs must stay open and welcoming.',
          };
        case 'Sagittarius':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Sagittarius and Gemini will never become boring for each other. It’s a match and a great one! Two Signs adore discovering something new, like people, places, or sensations. As friends, Sagittarius and Gemini are highly compatible - they can become soulmates and maintain long-term companionship. They will benefit from one another’s wisdom and powerful energy. Total understanding lets them form a strong, deep friendship. ',
          };
        case 'Capricorn':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Capricorn and Gemini see the world from different perspectives. However, their relationships are inspiring and dynamic. Once they accept each other’s differences, they can create a strong bond. The youthful and playful energy of Gemini and the mature view of Capricorn will let them discover for each other the world from a new perspective.  ',
          };
        case 'Aquarius':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Aquarius-Gemini relationships are about a deep level of mental connection. These two Signs share a love for solving life’s riddles. When Aquarius and Gemini know how to set boundaries, they can form and maintain strong friendships. If both Signs respect one another’s need for privacy and independence, they flourish and benefit from this exciting and energetic partnership',
          };
        case 'Pisces':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              "Pisces and Gemini's relationships are about inspiration. Two Signs with a dreamy view of the world — they understand their partner’s true intentions and make a great match. Their companionship stands for flexibility, as they complement one another’s imaginative perception. In friendship, they need to seek determination, as both Signs hate to make decisions. Also, Gemini must be careful not to hurt Pisces’s feelings.",
          };
      }
      break;
    case 'Cancer':
      switch (partnerSign) {
        case 'Aries':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Aries and Cancer are really different, but that’s the case of opposites attracting. Their affair may bring harmony and last a lifetime if both signs want to work on their relationship. Their friendship is a union of opposites, but surprisingly they are a great match. ',
          };
        case 'Taurus':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Cancer and Taurus are both traditional and family-oriented. It's easy for them to create a connection as long as they share the need for security and stability. Taurus and Cancer are very compatible friends, their relationship will definitely stand the test of time. Their friendship is all about support, as both Signs adore taking care of each other. ",
          };
        case 'Gemini':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Gemini and Cancer are polar opposites, yet they are a great complement to each other, as Cancer is about emotions and Gemini is a thinker. Together they make a good team. As friends, they can create fascinating, extraordinary partnerships. Gemini will take care of Cancer and Cancer will fully accept and support Gemini. They are both extremely social and share many similarities. With proper communication, they will not have any conflicts.',
          };
        case 'Cancer':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              "Cancer + Cancer is definitely a match! They are a great pair with heartwarming relationships. Both partners understand each other at the level of intuition, it's not even necessary to say things out loud. They can be great friends too, respecting, comforting, and protecting each other’s feelings. Two Cancer together always means fun! They will laugh as loud as possible and after that will have a long, gentle conversation about emotions and passions.\n",
          };
        case 'Leo':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              "Leo and Cancer may start their relationships as friends, it's common. Both Signs must learn how to compromise as long as they have total opposite energy. With due respect from the Leo side, they can become great friends. This companionship is about dedication and support, as well as about luxury and safety. They know how to make a deal - that's why  Leo and Cancer are not prone to arguing.\n",
          };
        case 'Virgo':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              "Virgo and Cancer are a great pair! They have the same perception of marriage, that's why it's easy for them to support their mental and emotional connection. As friends, they immediately feel a strong and deep connection and form a good team. Unlike other Signs, differences in Virgo and Cancer characters only make their bond stronger. These two will get along effortlessly as friends, lovers, or colleagues.",
          };
        case 'Libra':
          return {
            matchLovePoints: 1,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              'Libra and Cancer’s relationships may be controversial. However, they may be united by their need for a secure, unquestionable bond. Libra-Cancer relationships are about elegance and sensations. It’s not easy for Libra and Cancer to become friends, but when they do, they form deep and committed companionship. They see life in different lights, so it’s important to explore one another’s desires, thoughts, and feelings.',
          };
        case 'Scorpio':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Scorpio + Cancer = passion. Two highly sensitive and perceptive Signs make a deeply devoted duo, which appreciates loyalty. Their compatibility is remarkable - they become friends easily and not just friends but soulmates. The strong personality of both Signs makes their companionship exciting and full of discoveries. Together they will balance the companion's strong and weak points. ",
          };
        case 'Sagittarius':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              "Sagittarius-Cancer relationships need time to start flourishing. In fact, Sagittarius and Cancer have much to offer one another, but they need to explore patiently their partner's needs and desires. As friends Sagittarius and Cancer will need to seek respect for their companion’s need for time but not push this relationship. But once they acknowledge and respect each other’s attitudes, they can form a good team. ",
          };
        case 'Capricorn':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              "Capricorn and Cancer together create a perfect balance. Capricorn's love for stability and Cancer’s tenderness change both partners for the better. When Capricorn and Cancer unite as friends, they create a gentle and fruitful bond. Both companions will admire one another’s strong points and will be just fine with weaknesses. These two complement each other perfectly — Cancer will bring tenderness and Capricorn simplicity into this relationship.",
          };
        case 'Aquarius':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Aquarius and Cancer relationships are about lasting potential. They need time to figure each other out, but when they do, they make a great match. Both Signs are known for their loyalty, and as friends, they form a good team. Aquarius and Cancer both want things to go their way, that’s why they must seek respect and balance, otherwise, Cancer will start manipulating Aquarius and things will not work out. Still, they can teach others a lot - especially if Cancer will be more open about their feelings.',
          };
        case 'Pisces':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              'Pisces and Cancer may fill very important voids in each other’s lives. Their relationships are about fantasy, not logic - together they create their own world. Pisces and Cancer are great friends, their empathy lets them understand each other perfectly and provides emotional safety. They will encourage and inspire each other and will remain extremely loyal. Their similarities will let them create a healthy combination, maintaining long-term companionship.',
          };
      }
      break;
    case 'Leo':
      switch (partnerSign) {
        case 'Aries':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              "Aries-Leo relationships are about action and passion. Both partners need to respect each other's need for praise and recognition. These two fire signs are highly compatible, their relationship is full of energy, confidence, and passion",
          };
        case 'Taurus':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Leo and Taurus are a great couple. They are both loyal and have a thing for little life pleasures. Their love for the finer things in life may unite them. Loyalty will also make their friendship a life-long match full of mutual respect. If both Signs control their jealousy and will admit the important role of partner in their life, they will surely make a great match. ',
          };
        case 'Gemini':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Leo-Gemini relationships might be a great chance to realize your ambitions because both Signs have lots of energy. Combined, they can move mountains. Friendship comes naturally between these two. They know how to have fun and still spend quality time. Leo and Gemini will support one another’s dreams, standing by their companions' side. Together they will shine in the rays of support, positive thinking, and curiosity.",
          };
        case 'Cancer':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              "Leo and Cancer may start their relationships as friends, it's common. Both Signs must learn how to compromise as long as they have total opposite energy. With due respect from the Leo side, they can become great friends. This companionship is about dedication and support, as well as about luxury and safety. They know how to make a deal - that's why  Leo and Cancer are not prone to arguing.",
          };
        case 'Leo':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              "Leo + Leo are a stunning couple, they know how to be in the spotlight! Both partners are full of passion, energy, and enthusiasm. It's impossible not to feel the chemistry between them. As friends, they know how to create harmony although they both have powerful personalities. They have a lot in common, but still, a misunderstanding may take place, especially if both partners express the unwillingness to work on their trust issues. They will admire and respect each other, but they must remember to hold on to their jealousy.\n",
          };
        case 'Virgo':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              "Virgo and Leo are opposites, but they can create a strong and multifaceted emotional bond if both partners will take their time to acknowledge one another's needs and desires. As friends, they have little in common but can form a great team if both companions are ready to take it slow and respect one another’s point of view. They can teach each other a lot — Leo’s leadership energy will complement Virgo’s natural desire to analyze and plan everything. Thus, they will help each other grow emotionally and spiritually.\n",
          };
        case 'Libra':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              "Libra and Leo's relationships are based on a mutual understanding of the inner workings of their partner. Their love for glamorous events and family values at the same time unites and balances them. In companionship, they will adore spending time together as they share the same interests and values. Their friendship is about collaboration and mutual profit. Libra and Leo will always stand by their friend’s side, supporting and gently pushing at the same time. This companionship is like a safe place, where every partner can catch their breath and keep going forward \n",
          };
        case 'Scorpio':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Scorpio-Leo relationships will never be boring! They are dynamic, powerful, and spicy. They match each other's energy. Both Signs are extremely loyal and a bit possessive. As friends, they base their companionship on mutual respect, trust, and loyalty. They understand each other very well, this helps both companions to benefit from this vivid and powerful connection. When both companions seek respect for their partner’s independence, they find themselves in a trustworthy bond that gets stronger every day.\n",
          };
        case 'Sagittarius':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Sagittarius and Leo definitely know how to enjoy life and spend quality time together! Both Signs are social, warm, charismatic, and charming, they make a great match. Sagittarius and Leo are naturally comfortable with each other and can easily become lifelong friends, especially if they will learn to compromise and hold their stubbornness. Anyway, they match one another’s energy so well that conflicts are rare and pass quickly.',
          };
        case 'Capricorn':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              "Capricorn and Leo's relationships need time to start flourishing. Both partners must discover their similarities and differences to create a balanced couple. Their connection gets stronger and stronger with time and they must learn not to push their partner. Both Signs ​​have very different perspectives of life, still, kindness and loyalty unite them, creating a deeply devoted duo. They have a lot to teach each other - Capricorn will learn to express his feelings openly and Leo will become more patient and persistent ",
          };
        case 'Aquarius':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              'Aquarius + Leo are a great match. They are so different yet resembling at the same time. Together, they will highlight each other’s strong points and heal. As friends, they balance each other and bring energy and changes to one another’s lives. Their loyalty will unite them, but they must overcome themselves every day to make this partnership last. Both signs guard their freedom but they must strive toward balance.',
          };
        case 'Pisces':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Pisces and Leo are different in many aspects. Yet, both Signs are wildly attracted to each other, when they love each other they are able to create a deep, heartwarming connection. As friends, they can make really productive partnerships and unite in a deep and healthy mental connection. They will inspire one another, developing independence and genuineness in one another’s hearts. ',
          };
      }
      break;
    case 'Virgo':
      switch (partnerSign) {
        case 'Aries':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Aries-Virgo relationships may be challenging. You might think that you and your partner are the total opposites. Booth signs need to look for a common goal to unite their bond. Aries and Virgo are not zodiac signs you would expect to see together as friends, but they can teach each other a lot if they will seek similarities, not differences.',
          };
        case 'Taurus':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Virgo and Taurus are both Earth signs. Their love language is affection and gifts. Together, they create a great couple that needs to learn to agree to disagree. They can be good friends too — both Signs are reliable and dependable. Their friendship will get better and better over time, but Virgo and Taurus must avoid boredom, that might ruin their connection. ',
          };
        case 'Gemini':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              "Virgo-Gemini relationships are ruled by Mercury. Both Signs need to take their time and remember that both partners' attitudes are worth understanding. As friends, they may irritate each other, they would rather be great colleagues than companions. Still, this relationship has potential, if both Signs will learn to display sensitivity and maturity.",
          };
        case 'Cancer':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              "Virgo and Cancer are a great pair! They have the same perception of marriage, that's why it's easy for them to support their mental and emotional connection. As friends, they immediately feel a strong and deep connection and form a good team. Unlike other Signs, differences in Virgo and Cancer characters only make their bond stronger. These two will get along effortlessly as friends, lovers, or colleagues.",
          };
        case 'Leo':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              "Virgo and Leo are opposites, but they can create a strong and multifaceted emotional bond if both partners will take their time to acknowledge one another's needs and desires. As friends, they have little in common but can form a great team if both companions are ready to take it slow and respect one another’s point of view. They can teach each other a lot — Leo’s leadership energy will complement Virgo’s natural desire to analyze and plan everything. Thus, they will help each other grow emotionally and spiritually.\n",
          };
        case 'Virgo':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              "You totally understand your partner's needs — it is like looking into a mirror. Virgo-Virgo relationships are about security and consistency. As friends, Virgos create pleasant and gentle connections. Their friendship is about long and deep conversations and mental connection. ",
          };
        case 'Libra':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Libra and Virgo have a lot in common. They share a love for security, good food, and culture. Both partners need to remember to keep the fire of passion burning. As friends, they will support each other’s mental and spiritual growth. They will have no issue building a stable, loving companionship.',
          };
        case 'Scorpio':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Scorpio and Virgo are the most analytical signs in the zodiac. Long conversations are great, but both Signs need to act more than talk. Scorpio and Virgo can create a perfect connection on every level: they are great friends and colleagues — their passion for work and reflections unite them. \n',
          };
        case 'Sagittarius':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'The energy of Sagittarius and the practicality of Virgo balance each other. This relationship brings new discoveries for Virgo and a secure base for Sagittarius. They can easily become soulmates, their compatibility is surprisingly high. Loyalty and honesty will let them benefit from this connection\n',
          };
        case 'Capricorn':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Capricorn-Virgo relationships are nurturing and heartwarming. They are like two puzzle pieces fitting together. Their friendships is a dream match - both companions will understand perfectly one another’s thoughts and desires, supporting and creating a safe space for emotions \n',
          };
        case 'Aquarius':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'It’s a curious match, but a strong one! Aquarius and Virgo can highlight each other’s strong points and weaknesses. They are different, yet together they can make our world a better place. Virgo’s rationality and Aquarius’s passion for adventures will balance their companionship and let them achieve a lot as a team. ',
          };
        case 'Pisces':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Pisces + Virgo = healthy relationships that bring healing to each partner. Pisces and Virgo both have high empathy, together they create a warming and welcoming relationship. These two Signs will perfectly balance each other as friends, their compatibility is extremely high.\n',
          };
      }
      break;
    case 'Libra':
      switch (partnerSign) {
        case 'Aries':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Aries and Libra are directly opposite — but they can create a good match. Both signs need to compromise and leave their ego behind. Aries-Libra's friendship compatibility looks a little complicated. But the harmony coming from the union of Mars and Venus may bring deep and gentle understanding into this relationship. ",
          };
        case 'Taurus':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Libra and Taurus are karmically linked. Their relationships are warming and welcoming, and they have much to teach one another as lovers and as friends. Their friendship is like the joining of two parts to completion. Libra and Taurus “get” each other, and share a love for art, culture, and quiet time. Still, they are different in many ways, and both Signs must learn to adapt and compromise.\n',
          };
        case 'Gemini':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Libra and Gemini are a great match. Together they combine the forces of love and communication. Their friendship is about deep mental connection. Passion for discovery and exploration unites them. Their favorite thing to do together is experience new places, sensations, and people.\n',
          };
        case 'Cancer':
          return {
            matchLovePoints: 1,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              'Libra and Cancer’s relationships may be controversial. However, they may be united by their need for a secure, unquestionable bond. Libra-Cancer relationships are about elegance and sensations. It’s not easy for Libra and Cancer to become friends, but when they do, they form deep and committed companionship. They see life in different lights, so it’s important to explore one another’s desires, thoughts, and feelings.\n',
          };
        case 'Leo':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              "Libra and Leo's relationships are based on a mutual understanding of the inner workings of their partner. Their love for glamorous events and family values at the same time unites and balances them. In companionship, they will adore spending time together as they share the same interests and values. Their friendship is about collaboration and mutual profit. Libra and Leo will always stand by their friend’s side, supporting and gently pushing at the same time. This companionship is like a safe place, where every partner can catch their breath and keep going forward \n",
          };
        case 'Virgo':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Libra and Virgo have a lot in common. They share a love for security, good food, and culture. Both partners need to remember to keep the fire of passion burning. As friends, they will support each other’s mental and spiritual growth. They will have no issue building a stable, loving companionship.',
          };
        case 'Libra':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              "You totally understand your partner's needs — it is like looking into a mirror. Libra-Libra relationships are about security and mutual love for culture and beauty. As friends, they will create a strong connection, fighting against injustice and seeking peace and harmony\n",
          };
        case 'Scorpio':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              'It’s getting hot! Scorpio and Libra are polar opposites, yet their relationships are so magnetizing that they can resist. They can become soulmates effortlessly, as long as they will unite their ambitions for a mutual goal. Scorpio and Libra will complement each other, building a strong friendship as long as they learn to recognize and respect their differences.\n',
          };
        case 'Sagittarius':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Libra and Sagittarius can feel a deep, warm connection. They know how to balance each other, together they can move mountains. Their companionship is about sharing and learning. Both Signs adore exploring and discovering, together they will create a vivid, exciting, and heartwarming duo. \n',
          };
        case 'Capricorn':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Capricorn and Libra must seek their similarities rather than differences. They can be a great match if both partners will learn to compromise. Capricorn and Libra can build a strong and lifelong relationship as friends, united by the one mutual goal they will form a great team.\n',
          };
        case 'Aquarius':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Aquarius and Libra are like-minded, adventurous people. There is an immediate bond between these two — their love for freedom and discoveries unite them, letting Aquarius and Libra build a strong friendship. Yet, they must talk about their feelings, as both Signs may avoid conflicts.\n',
          };
        case 'Pisces':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Pisces and Libra understand each other perfectly. Together they make a great team, and problems between these two are rare. Pisces and Libra are great friends too. Their desire to be helpful and support the loved one makes their companionship a long and strong unit.\n',
          };
      }
      break;
    case 'Scorpio':
      switch (partnerSign) {
        case 'Aries':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              "Aries-Scorpio relationships are dynamic and passionate. Partners must respect each other's need for privacy. They can be a great duo if both partners will learn how to cooperate. Aries and Scorpio can be either strong allies or mortal enemies. That’s why patience and respect are crucial for these relationships. \n",
          };
        case 'Taurus':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Scorpio and Taurus have a special, bit of complex connection. Both partners need to learn to agree to disagree. As friends, they feel a strong connection, and this can be a strong bond for life. They complement each other so well that you may think of two puzzle pieces. But still, Scorpio and Taurus must work hard to make their relationship last\n',
          };
        case 'Gemini':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Scorpio and Gemini must acknowledge each other’s differences and learn how to accept them. Scorpio-Gemini relationships are all about passion, loyalty, and mental connection. Scorpio-Gemini friendship may be strong and full of mutual respect, especially if both companions are fascinated by each other’s differences. To avoid misunderstandings both Signs must stay open and welcoming.',
          };
        case 'Cancer':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Scorpio + Cancer = passion. Two highly sensitive and perceptive Signs make a deeply devoted duo, which appreciates loyalty. Their compatibility is remarkable - they become friends easily and not just friends but soulmates. The strong personality of both Signs makes their companionship exciting and full of discoveries. Together they will balance the companion's strong and weak points. \n",
          };
        case 'Leo':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Scorpio-Leo relationships will never be boring! They are dynamic, powerful, and spicy. They match each other's energy. Both Signs are extremely loyal and a bit possessive. As friends, they base their companionship on mutual respect, trust, and loyalty. They understand each other very well, this helps both companions to benefit from this vivid and powerful connection. When both companions seek respect for their partner’s independence, they find themselves in a trustworthy bond that gets stronger every day.\n",
          };
        case 'Virgo':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Scorpio and Virgo are the most analytical signs in the zodiac. Long conversations are great, but both Signs need to act more than talk. Scorpio and Virgo can create a perfect connection on every level: they are great friends and colleagues — their passion for work and reflections unite them. \n',
          };
        case 'Libra':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              'It’s getting hot! Scorpio and Libra are polar opposites, yet their relationships are so magnetizing that they can resist. They can become soulmates effortlessly, as long as they will unite their ambitions for a mutual goal. Scorpio and Libra will complement each other, building a strong friendship as long as they learn to recognize and respect their differences.\n',
          };
        case 'Scorpio':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Scorpio + Scorpio = mutual attraction and instant psychic connection. If they want to build a long-term relationship, they need to learn to balance. Their friendship is usually a unit of two grown-ups, but both companions must work a lot to make this friendship last \n',
          };
        case 'Sagittarius':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 1,
            description:
              "Sagittarius and Scorpio have a lot to offer one another. Their relationships must be slow-tempted and cautious: the chemistry is irresistible, yet it's better to take it slow, this will let your relationship blossom. Sagittarius and Scorpio can spend a lot of time apart, this will not weaken their bond or bring conflicts. ",
          };
        case 'Capricorn':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Capricorn and Scorpio can be a great couple if they respect each other’s ambitions and learn how to support their panther, not compete. Capricorn and Scorpio are both stubborn and cautious, they need time to become more open, although trust comes naturally into this companionship. ',
          };
        case 'Aquarius':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              'If Scorpio will not try to trick or manipulate Aquarius, these two Signs may benefit from this relationship. With due patience, their relationship can get Aquarius and Scorpio whatever they need. The ability to communicate is essential when Aquarius and Scorpio become friends, as risks of misunderstanding are high. ',
          };
        case 'Pisces':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Pisces and Scorpio can create a heartwarming, intimate connection. Don't forget to talk about your feelings. Long conversations will help your relationship blossom. As friends Pisces and Scorpio are naturally good for each other. They will trust their companion, knowing that they are the one for each other.",
          };
      }
      break;
    case 'Sagittarius':
      switch (partnerSign) {
        case 'Aries':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Sagittarius and Aries have a lot in common. It’s a match made in heaven — these two will never get bored by each other. They make good friends too - their passion for life makes them a great team. Aries and Sagittarius are both enthusiastic and cheerful, they comprehend each other well. \n',
          };
        case 'Taurus':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Sagittarius and Taurus are a complementary match. Their relationships are beneficial — they have much to gain from their bond. Both Signs are extremely lucky, and their friendship is all about respect and communication. As friends, they will inspire each other - Sagittarius will benefit from the Taurus’s stubborn energy and Taurus will learn a lot about feelings.\n',
          };
        case 'Gemini':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              ' Sagittarius and Gemini will never become boring for each other. It’s a match and a great one! Two Signs adore discovering something new, like people, places, or sensations. As friends, Sagittarius and Gemini are highly compatible - they can become soulmates and maintain long-term companionship. They will benefit from one another’s wisdom and powerful energy. Total understanding lets them form a strong, deep friendship. \n',
          };
        case 'Cancer':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              "Sagittarius-Cancer relationships need time to start flourishing. In fact, Sagittarius and Cancer have much to offer one another, but they need to explore patiently their partner's needs and desires. As friends Sagittarius and Cancer will need to seek respect for their companion’s need for time but not push this relationship. But once they acknowledge and respect each other’s attitudes, they can form a good team. \n",
          };
        case 'Leo':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Sagittarius and Leo definitely know how to enjoy life and spend quality time together! Both Signs are social, warm, charismatic, and charming, they make a great match. Sagittarius and Leo are naturally comfortable with each other and can easily become lifelong friends, especially if they will learn to compromise and hold their stubbornness. Anyway, they match one another’s energy so well that conflicts are rare and pass quickly.\n',
          };
        case 'Virgo':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'The energy of Sagittarius and the practicality of Virgo balance each other. This relationship brings new discoveries for Virgo and a secure base for Sagittarius. They can easily become soulmates, their compatibility is surprisingly high. Loyalty and honesty will let them benefit from this connection\n',
          };
        case 'Libra':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Libra and Sagittarius can feel a deep, warm connection. They know how to balance each other, together they can move mountains. Their companionship is about sharing and learning. Both Signs adore exploring and discovering, together they will create a vivid, exciting, and heartwarming duo. \n',
          };
        case 'Scorpio':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 1,
            description:
              "Sagittarius and Scorpio have a lot to offer one another. Their relationships must be slow-tempted and cautious: the chemistry is irresistible, yet it's better to take it slow, this will let your relationship blossom. Sagittarius and Scorpio can spend a lot of time apart, this will not weaken their bond or bring conflicts. ",
          };
        case 'Sagittarius':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Sagittarius-Sagittarius are a great couple! They understand each other's need for freedom and exploration. When they become friends, they may challenge each other and still it’s all about deep and gentle connection. Both companions will care about their loved one, supporting and creating a safe space for emotions.",
          };
        case 'Capricorn':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'If Capricorn and Sagittarius will learn from each other, they can make a good team. They are so different, yet they have much to offer one another. Still, their stubbornness may lead to misunderstandings or conflicts. Both companions must listen to each other carefully and express themselves clearly.\n',
          };
        case 'Aquarius':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Aquarius and Sagittarius can give each other the ultimate gift of liberation. Together they are a creative and unique couple. As friends, they will combine Aquarius’ intelligence and Sagittarius’ powerful energy. Their companionship is about cooperation of Fire and Air, that makes them a dynamic team',
          };
        case 'Pisces':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'If Pisces and Sagittarius will overcome their tendency to depend too heavily on each other, they will create a dream match. They are great friends - they can learn a lot from each other, Pisces will finally leave their comfort zone and Sagittarius will learn to slow down ',
          };
      }
      break;
    case 'Capricorn':
      switch (partnerSign) {
        case 'Aries':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Aries-Capricorn relationships are controversial. They have a lot in common, they share this stubborn energy and understand each other perfectly, but they need to work hard to maintain balance. Aries and Capricorn can become lifetime friends if they have a deep level of respect for one another.',
          };
        case 'Taurus':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Capricorn and Taurus admire one another’s dedication and strength and form a great couple. They share a need for traditional, rational, sensible, and family-oriented relationships. As friends, Taurus and Capricorn must seek different approaches. They know well how to make life a stable and peaceful place, that's why it's important to remember to have fun. Their friendship is about long and deep conversations and mental connection. \n",
          };
        case 'Gemini':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Capricorn and Gemini see the world from different perspectives. However, their relationships are inspiring and dynamic. Once they accept each other’s differences, they can create a strong bond. The youthful and playful energy of Gemini and the mature view of Capricorn will let them discover for each other the world from a new perspective.  \n',
          };
        case 'Cancer':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              "Capricorn and Cancer together create a perfect balance. Capricorn's love for stability and Cancer’s tenderness change both partners for the better. When Capricorn and Cancer unite as friends, they create a gentle and fruitful bond. Both companions will admire one another’s strong points and will be just fine with weaknesses. These two complement each other perfectly — Cancer will bring tenderness and Capricorn simplicity into this relationship.\n",
          };
        case 'Leo':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              "Capricorn and Leo's relationships need time to start flourishing. Both partners must discover their similarities and differences to create a balanced couple. Their connection gets stronger and stronger with time and they must learn not to push their partner. Both Signs ​​have very different perspectives of life, still, kindness and loyalty unite them, creating a deeply devoted duo. They have a lot to teach each other - Capricorn will learn to express his feelings openly and Leo will become more patient and persistent \n",
          };
        case 'Virgo':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Capricorn-Virgo relationships are nurturing and heartwarming. They are like two puzzle pieces fitting together. Their friendships is a dream match - both companions will understand perfectly one another’s thoughts and desires, supporting and creating a safe space for emotions \n',
          };
        case 'Libra':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Capricorn and Libra must seek their similarities rather than differences. They can be a great match if both partners will learn to compromise. Capricorn and Libra can build a strong and lifelong relationship as friends, united by the one mutual goal they will form a great team.\n',
          };
        case 'Scorpio':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Capricorn and Scorpio can be a great couple if they respect each other’s ambitions and learn how to support their panther, not compete. Capricorn and Scorpio are both stubborn and cautious, they need time to become more open, although trust comes naturally into this companionship. ',
          };
        case 'Sagittarius':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'If Capricorn and Sagittarius will learn from each other, they can make a good team. They are so different, yet they have much to offer one another. Still, their stubbornness may lead to misunderstandings or conflicts. Both companions must listen to each other carefully and express themselves clearly.\n',
          };
        case 'Capricorn':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              'Two Capricorns make a deeply devoted duo, which appreciates loyalty. Not locking horns with their panthers is the main need of this couple. They can become good friends, as long as they respect one another’s a somewhat old-fashioned point of view. Together they will achieve a lot, and their consistency and perseverance will bear fruits. ',
          };
        case 'Aquarius':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              "Together, Capricorn and Aquarius can have it all if they overcome the unwillingness to compromise. Don't forget to have fun together, it will strengthen your bond. As friends, they are a match and a dream one. Their ability to communicate will make this companionship flourish. \n",
          };
        case 'Pisces':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Capricorn + Pisces = harmony and balance. Even though their love language is control, Capricorn must not forget that Pisces hates being kept. In companionship, Capricorn may become a solid base for Pisces’ dreams and desires. If so, both companions will find out that they are different yet in a good way and will benefit from Pisces’ emotional depth and Capricorn’ audacity ',
          };
      }
      break;
    case 'Aquarius':
      switch (partnerSign) {
        case 'Aries':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Aquarius and Aries are highly creative pair. They are like playful best friends. This friendship can be reborn into a gentle love bond. But if not, they will become great buddies. The best aspect of their friendship is finding commonality in the differences. When so, their bond is full of fun and excitement\n',
          };
        case 'Taurus':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              'Aquarius and Taurus are extremely opposite. They can create a great alliance if they will learn how to balance each other out. Their friendship compatibility is not that high, as opposites do not always attract. But they have great potential, especially when Taurus’ logic complements Aquarius’ romantic view..\n',
          };
        case 'Gemini':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Aquarius-Gemini relationships are about a deep level of mental connection. These two Signs share a love for solving life’s riddles. When Aquarius and Gemini know how to set boundaries, they can form and maintain strong friendships. If both Signs respect one another’s need for privacy and independence, they flourish and benefit from this exciting and energetic partnership\n',
          };
        case 'Cancer':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Aquarius and Cancer relationships are about lasting potential. They need time to figure each other out, but when they do, they make a great match. Both Signs are known for their loyalty, and as friends, they form a good team. Aquarius and Cancer both want things to go their way, that’s why they must seek respect and balance, otherwise, Cancer will start manipulating Aquarius and things will not work out. Still, they can teach others a lot - especially if Cancer will be more open about their feelings.\n',
          };
        case 'Leo':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              'Aquarius + Leo are a great match. They are so different yet resembling at the same time. Together, they will highlight each other’s strong points and heal. As friends, they balance each other and bring energy and changes to one another’s lives. Their loyalty will unite them, but they must overcome themselves every day to make this partnership last. Both signs guard their freedom but they must strive toward balance.\n',
          };
        case 'Virgo':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'It’s a curious match, but a strong one! Aquarius and Virgo can highlight each other’s strong points and weaknesses. They are different, yet together they can make our world a better place. Virgo’s rationality and Aquarius’s passion for adventures will balance their companionship and let them achieve a lot as a team. ',
          };
        case 'Libra':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Aquarius and Libra are like-minded, adventurous people. There is an immediate bond between these two — their love for freedom and discoveries unite them, letting Aquarius and Libra build a strong friendship. Yet, they must talk about their feelings, as both Signs may avoid conflicts.\n',
          };
        case 'Scorpio':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              'If Scorpio will not try to trick or manipulate Aquarius, these two Signs may benefit from this relationship. With due patience, their relationship can get Aquarius and Scorpio whatever they need. The ability to communicate is essential when Aquarius and Scorpio become friends, as risks of misunderstanding are high. ',
          };
        case 'Sagittarius':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Aquarius and Sagittarius can give each other the ultimate gift of liberation. Together they are a creative and unique couple. As friends, they will combine Aquarius’ intelligence and Sagittarius’ powerful energy. Their companionship is about cooperation of Fire and Air, that makes them a dynamic team',
          };
        case 'Capricorn':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              "Together, Capricorn and Aquarius can have it all if they overcome the unwillingness to compromise. Don't forget to have fun together, it will strengthen your bond. As friends, they are a match and a dream one. Their ability to communicate will make this companionship flourish. \n",
          };
        case 'Aquarius':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Aquarius-Aquarius relationships are multifaceted. They are a great team, but still, they must remember to work on the intimacy in this relationship. Two Aquarius can build a strong, fruitful friendship, especially if they will not remain silent and will discuss their desires and feelings.',
          };
        case 'Pisces':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Pisces-Aquarius relationships are about introspective and dreams. They are a great couple, they bring each other comfort and change at the same time. When Pisces and Aquarius become friends, they create strong and supportive companionship, combinating inventiveness and kindness\n',
          };
      }
      break;
    case 'Pisces':
      switch (partnerSign) {
        case 'Aries':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Aries-Pisces relationships are never boring. They are not naturally super compatible as friends and are difficult to combine. Both Signs must work hard to make a relationship last. But Aries’s directness with Pisces’s intuitive perception makes them a great team. They have a lot in common - generosity, creativity and guilty pleasure for luxuries \n',
          };
        case 'Taurus':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Pisces and Taurus usually have harmonious, dreamy, and stable relationships. These two Signs have much to offer one another. They can become close, nurturing friends. They are definitely soulmates with mutual interests and respect! As friends, they will understand and support each other, especially if they share the same values.\n',
          };
        case 'Gemini':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              "Pisces and Gemini's relationships are about inspiration. Two Signs with a dreamy view of the world — they understand their partner’s true intentions and make a great match. Their companionship stands for flexibility, as they complement one another’s imaginative perception. In friendship, they need to seek determination, as both Signs hate to make decisions. Also, Gemini must be careful not to hurt Pisces’s feelings.",
          };
        case 'Cancer':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 3,
            description:
              'Pisces and Cancer may fill very important voids in each other’s lives. Their relationships are about fantasy, not logic - together they create their own world. Pisces and Cancer are great friends, their empathy lets them understand each other perfectly and provides emotional safety. They will encourage and inspire each other and will remain extremely loyal. Their similarities will let them create a healthy combination, maintaining long-term companionship.\n',
          };
        case 'Leo':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Pisces and Leo are different in many aspects. Yet, both Signs are wildly attracted to each other, when they love each other they are able to create a deep, heartwarming connection. As friends, they can make really productive partnerships and unite in a deep and healthy mental connection. They will inspire one another, developing independence and genuineness in one another’s hearts. ',
          };
        case 'Virgo':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Pisces + Virgo = healthy relationships that bring healing to each partner. Pisces and Virgo both have high empathy, together they create a warming and welcoming relationship. These two Signs will perfectly balance each other as friends, their compatibility is extremely high.\n',
          };
        case 'Libra':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'Pisces and Libra understand each other perfectly. Together they make a great team, and problems between these two are rare. Pisces and Libra are great friends too. Their desire to be helpful and support the loved one makes their companionship a long and strong unit.\n',
          };
        case 'Scorpio':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              "Pisces and Scorpio can create a heartwarming, intimate connection. Don't forget to talk about your feelings. Long conversations will help your relationship blossom. As friends Pisces and Scorpio are naturally good for each other. They will trust their companion, knowing that they are the one for each other.",
          };
        case 'Sagittarius':
          return {
            matchLovePoints: 2,
            matchFriendshipPoints: 2,
            matchCareerPoints: 2,
            description:
              'If Pisces and Sagittarius will overcome their tendency to depend too heavily on each other, they will create a dream match. They are great friends - they can learn a lot from each other, Pisces will finally leave their comfort zone and Sagittarius will learn to slow down ',
          };
        case 'Capricorn':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 2,
            description:
              'Capricorn + Pisces = harmony and balance. Even though their love language is control, Capricorn must not forget that Pisces hates being kept. In companionship, Capricorn may become a solid base for Pisces’ dreams and desires. If so, both companions will find out that they are different yet in a good way and will benefit from Pisces’ emotional depth and Capricorn’ audacity ',
          };
        case 'Aquarius':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Pisces-Aquarius relationships are about introspective and dreams. They are a great couple, they bring each other comfort and change at the same time. When Pisces and Aquarius become friends, they create strong and supportive companionship, combining inventiveness and kindness',
          };
        case 'Pisces':
          return {
            matchLovePoints: 3,
            matchFriendshipPoints: 3,
            matchCareerPoints: 3,
            description:
              'Two Pisces make an emotional and sensitive duo. It’s a great match - both partners share the love for beauty and are able to establish a deep spiritual connection.  They will communicate wonderfully as friends. Pisces will always be ready to help their companion and be a shoulder to cry on.\n',
          };
      }
      break;
  }
  return;
};
