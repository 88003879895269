import classNames from 'classnames';
import { ComponentProps, ReactNode, forwardRef } from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';

import LoadingIndicator from '../loading-indicator/loading-indicator';
import styles from './button.module.scss';

export const enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  OPTION_BUTTON = 'optionButton',
  OPTION_BUTTON_SELECTED = 'optionButtonSelected',
  OPTION_BUTTON_WITH_ICON = 'optionButtonWithIcon',
  OPTION_BUTTON_WITH_ICON_SELECTED = 'optionButtonWithIconSelected',
  OPTION_BUTTON_NO_BORDER = 'optionButtonNoBorder',
  OPTION_BUTTON_NO_BORDER_SELECTED = 'optionButtonNoBorderSelected',
  OPTION_BUTTON_WITH_IMAGE = 'optionButtonWithImage',
  OPTION_BUTTON_WITH_IMAGE_SELECTED = 'optionButtonWithImageSelected',
  OPTION_BUTTON_WITH_IMAGE_NOT_SELECTED = 'optionButtonWithImageNotSelected',
  OPTION_BUTTON_SQUARE = 'optionButtonSquare',
  OPTION_BUTTON_SQUARE_SELECTED = 'optionButtonSquareSelected',
  OPTION_BUTTON_LEFT_IMAGE = 'optionButtonLeftImage',
  OPTION_BUTTON_LEFT_IMAGE_NOT_SELECTED = 'optionButtonLeftImageNotSelected',
}

interface Props extends ComponentProps<typeof BootstrapButton> {
  showLoadingIndicator?: boolean;
  image?: ReactNode;
  size?: 'lg' | 'sm';
}

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      IconComponent,
      iconClassName,
      className,
      disabled,
      showLoadingIndicator = false,
      children,
      ...props
    }: Props,
    ref,
  ) => {
    const { variant, size } = props;
    let variantClassName: string | undefined;
    switch (variant) {
      case ButtonVariant.PRIMARY:
      case undefined:
        variantClassName = styles.containerPrimary;
        break;
      case ButtonVariant.SECONDARY:
        variantClassName = styles.containerSecondary;
        break;
      case ButtonVariant.TERTIARY:
        variantClassName = styles.containerTertiary;
        break;
      case ButtonVariant.OPTION_BUTTON:
        variantClassName = styles.containerOptionButton;
        break;
      case ButtonVariant.OPTION_BUTTON_SELECTED:
        variantClassName = styles.containerOptionButtonSelected;
        break;
      case ButtonVariant.OPTION_BUTTON_WITH_ICON:
        variantClassName = styles.containerOptionButtonWithIcon;
        break;
      case ButtonVariant.OPTION_BUTTON_WITH_ICON_SELECTED:
        variantClassName = styles.containerOptionButtonWithIconSelected;
        break;
      case ButtonVariant.OPTION_BUTTON_NO_BORDER:
        variantClassName = styles.containerOptionButtonNoBorder;
        break;
      case ButtonVariant.OPTION_BUTTON_NO_BORDER_SELECTED:
        variantClassName = styles.containerOptionButtonNoBorderSelected;
        break;
      case ButtonVariant.OPTION_BUTTON_WITH_IMAGE:
        variantClassName = styles.containerOptionButtonWithImage;
        break;
      case ButtonVariant.OPTION_BUTTON_WITH_IMAGE_SELECTED:
        variantClassName = styles.containerOptionButtonWithImageSelected;
        break;
      case ButtonVariant.OPTION_BUTTON_WITH_IMAGE_NOT_SELECTED:
        variantClassName = styles.containerOptionButtonWithImageNotSelected;
        break;
      case ButtonVariant.OPTION_BUTTON_SQUARE:
        variantClassName = styles.containerOptionButtonSquared;
        break;
      case ButtonVariant.OPTION_BUTTON_SQUARE_SELECTED:
        variantClassName = styles.containerOptionButtonSquareSelected;
        break;
      case ButtonVariant.OPTION_BUTTON_LEFT_IMAGE:
        variantClassName = styles.containerOptionButtonLeftImage;
        break;
      case ButtonVariant.OPTION_BUTTON_LEFT_IMAGE_NOT_SELECTED:
        variantClassName = styles.containerOptionButtonLeftImageNotSelected;
        break;
    }

    const sizeClassName = classNames({
      [styles.lg]: size === 'lg',
      [styles.sm]: size === 'sm',
    });

    return (
      <BootstrapButton
        ref={ref}
        className={classNames(
          styles.container,
          variantClassName,
          sizeClassName,
          className,
        )}
        disabled={disabled || showLoadingIndicator}
        {...props}>
        {showLoadingIndicator ? (
          <LoadingIndicator />
        ) : (
          <>
            {IconComponent ? (
              <IconComponent className={classNames(iconClassName)} />
            ) : null}
            <p>{children}</p>
          </>
        )}
      </BootstrapButton>
    );
  },
);

Button.displayName = 'Button';
export default Button;
