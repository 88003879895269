import { axiosAnalytics } from 'src/api/axios';
import { isDevelopment } from 'src/utils';

import {
  GA_ID,
  PINTEREST_ACCOUNT_ID,
  PINTEREST_EVENT_NAME_TO_RIGHT_CONVENTION,
} from '@features/Analytics/constants';

import { TpPinterestEvent } from '../types';

export const postPinterestEvent = async ({
  eventName,
  eventId,
  options,
}: TpPinterestEvent) => {
  try {
    const { data } = await axiosAnalytics.post('pinterest/conversion', {
      ad_account_id: PINTEREST_ACCOUNT_ID,
      test: isDevelopment,
      data: [
        {
          event_name: PINTEREST_EVENT_NAME_TO_RIGHT_CONVENTION[eventName],
          action_source: 'web',
          event_time: Math.floor(Date.now() / 1000),
          event_id: eventId,
          hashed_maids: [GA_ID ?? 'G-XC3F5TQJ8T'],
          user_data: {
            em: options?.email != null ? [options.email] : [],
            client_user_agent:
              typeof window !== 'undefined' && window.navigator.userAgent,
            ...options,
          },
        },
      ],
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
