import { ComponentType, ReactElement, ReactNode } from 'react';

export enum QuizStepType {
  OTHER,
  SINGLE_OPTION_SELECT,
  MULTIPLE_OPTIONS_SELECT,
  TEXT_CARD_WITH_BACKGROUND_IMAGE,
}

export type QuizSection =
  | 'Introduction'
  | 'Life purpose'
  | 'Mood & Emotions'
  | 'Personalization'
  | 'Relationship status';

export enum QuizStep {
  WELCOME,
  WELCOME_AB_TEST,
  WELCOME_FOUR_VARIANTS_AB_TEST,
  WELCOME_THREE_VARIANTS_AB_TEST,
  INTRO,
  FACING_REPEATING,
  ASPECT,
  DESIRED_RELATIONSHIP,
  PREVIOUS_RELATIONSHIP,
  TOXIC_RELATIONSHIP,
  DISCOVER_SLIDER,
  PURPOSE,
  JOB_LOVING,
  FINANCIAL_GOAL,
  LIFE_ASPECTS,
  WORK_OUT,
  SPIRITUAL_GOAL,
  IN_LOVE,
  ROMANTIC_FEELINGS,
  KARMIC_LESSONS,
  WE_GOT_YOU,
  LIFE,
  MOOD,
  CRITICS_SENSITIVE,
  HOT_TEMPRED,
  MOOD_SWINGS,
  TAKE_COMMAND,
  EMOTIONS,
  GENDER,
  NAME,
  GREETINGS,
  DATE_OF_BIRTH,
  HOMETOWN,
  BIRTH_TIME_KNOWLEDGE,
  NO_PROBLEM,
  BIRTH_TIME,
  SIGN_HELPED,
  RELATIONSHIP,
  SEX, //SINGLE
  COMFORTABLE, //SINGLE
  GOOD_ENOUGH, //SINGLE
  RELATIONSHIP_GOAL, //SINGLE
  LONG_TERM, //SINGLE
  PARTNER_ASTROLOGY_SIGN, //IN RELATIONS
  PARTNER_BIRTH_TIME_KNOWLEDGE, //IN RELATIONS
  PARTNER_NO_PROBLEM, //IN RELATIONS
  PARTNER_BIRTH_TIME, //IN RELATIONS
  PARTNER_HOMETOWN, //IN RELATIONS
  DISCUSSING_PROBLEMS, //IN RELATIONS
  AFFECT_RELATIONSHIP, //IN RELATIONS
  SEX_RELATIONSHIP, //IN RELATIONS
  FINAL_SUMMARY, //BOTH
  ENTER_EMAIL, //BOTH
  PARTNER_COMPATIBILITY, //IN RELATIONS
  BEST_MATCHES, //SINGLE
  ANIMATION_SCREENS,
  PERSONAL_PLAN_HIGHLIGHTS,
}

interface BaseQuizStep {
  section: string;
  type: QuizStepType;
  step: QuizStep;
  title: ReactNode;
  subtitle?: ReactNode;
}

export interface OtherQuizStep extends BaseQuizStep {
  type: QuizStepType.OTHER;
}

export interface SelectQuizStepOption<T> {
  value: T;
  label: ReactNode;
}

export interface SingleOptionSelectQuizStepOption
  extends SelectQuizStepOption<number> {
  image?: ReactNode;
}

export interface SingleOptionSelectQuizStep extends BaseQuizStep {
  type: QuizStepType.SINGLE_OPTION_SELECT;
  options: SingleOptionSelectQuizStepOption[];
  skipOption?: SelectQuizStepOption<number>;
  nextButtonText?: string;
}

export interface MultipleOptionsSelectQuizStepOption
  extends SelectQuizStepOption<string> {
  image?: ReactNode;
}

export interface MultipleOptionsSelectQuizStep extends BaseQuizStep {
  type: QuizStepType.MULTIPLE_OPTIONS_SELECT;
  options: MultipleOptionsSelectQuizStepOption[];
  skipOption?: SelectQuizStepOption<string | string[]>;
  nextButtonText?: string;
}

export interface TextCardWithBackgroundImage extends BaseQuizStep {
  type: QuizStepType.TEXT_CARD_WITH_BACKGROUND_IMAGE;
  nextButtonText?: string;
  TextContentComponent?: ReactElement;
  TitleComponent?: ComponentType;
}

export const LIFE_PURPOSE = {
  'Work & Career': 0,
  'Wealth & Money': 1,
  'Love & Relationships': 2,
  'Spirituality & Wellness': 3,
} as const;

export type Tp_LIFE_PURPOSE =
  | (typeof LIFE_PURPOSE)[keyof typeof LIFE_PURPOSE]
  | number;

export type Step =
  | OtherQuizStep
  | SingleOptionSelectQuizStep
  | MultipleOptionsSelectQuizStep
  | TextCardWithBackgroundImage;

export const isStepProgressShown = (step: Step) => {
  switch (step.type) {
    case QuizStepType.OTHER:
      switch (step.step) {
        case QuizStep.DISCOVER_SLIDER:
        case QuizStep.NAME:
        case QuizStep.DATE_OF_BIRTH:
        case QuizStep.HOMETOWN:
        case QuizStep.PARTNER_HOMETOWN:
        case QuizStep.BIRTH_TIME:
        case QuizStep.PARTNER_BIRTH_TIME:
        case QuizStep.PARTNER_ASTROLOGY_SIGN:
          return true;
        default:
          return false;
      }

    default:
      return true;
  }
};
